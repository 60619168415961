import {storyblokEditable, StoryblokComponent} from "@storyblok/react";
import BaseLayout from "./layouts/BaseLayout";

const StoryblokPage = (props: { blok: any; navigation: any; }) => {
    const blok = props.blok;

    return <main {...storyblokEditable(blok)}>
        {blok.body.map((nestedBlok: any) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid}/>
        ))}
    </main>

};

export default StoryblokPage;